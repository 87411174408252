import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import { Parallax } from 'react-scroll-parallax'
import IconLoupe from '~/images/icon/loupe.svg'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const advantageTopStyle = css`
  position: relative;

  & > .body {
    position: relative;
    z-index: 2;
    max-width: var(--width-max-content);
    margin-right: auto;
    margin-left: auto;
  }

  & > .body > .title {
    display: flex;
    justify-content: center;
    font-size: ${rem(30)};
    font-weight: 700;
    letter-spacing: 0.05em;
    text-align: center;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      font-size: ${rem(44)};
    }
  }

  & > .body > .title > .inner {
    position: relative;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 3px;
      height: 40px;
      border-radius: 10px;
      background-color: var(--color-background-black);

      @media screen and (${STYLE.MEDIA.TABLET}) {
        bottom: 4px;
        width: 4px;
        height: 64px;
      }
    }

    &::before {
      left: 0;
      transform: rotate(-12deg);

      @media screen and (${STYLE.MEDIA.TABLET}) {
        left: -24px;
      }
    }

    &::after {
      right: 0;
      transform: rotate(12deg);

      @media screen and (${STYLE.MEDIA.TABLET}) {
        right: -24px;
      }
    }
  }

  & > .body > .title > .inner > .connector {
    font-size: ${rem(20)};

    @media screen and (${STYLE.MEDIA.TABLET}) {
      margin-top: 0.25em;
      font-size: ${rem(30)};
    }
  }

  & > .body > .title > .inner > .logo {
    display: block;
    width: 214px;
    margin-right: auto;
    margin-left: auto;
    aspect-ratio: 330 / 90;
    transform: translateX(-12px);

    @media screen and (${STYLE.MEDIA.TABLET}) {
      width: 296px;
      margin-top: 0.15em;
      margin-right: 16px;
      transform: translateX(0);
    }
  }

  & > .body > .items {
    counter-reset: item;
  }

  & > .body > .items > .item {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    margin-top: 48px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-direction: row;
      margin-top: 64px;

      &:nth-child(odd) {
        flex-direction: row-reverse;
      }
    }
  }

  & > .body > .items > .item > .content {
    flex-basis: 0;
    flex-grow: 1;
    margin-top: 32px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      margin-top: 0;
      margin-right: 48px;
      margin-left: 0;
      will-change: transform;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      padding-bottom: 1em;
    }
  }

  & > .body > .items > .item:nth-child(odd) > .content {
    @media screen and (${STYLE.MEDIA.TABLET}) {
      margin-right: 0;
      margin-left: 48px;
    }
  }

  & > .body > .items > .item > .content > .title {
    position: relative;
    padding-left: 68px;
    font-size: ${rem(22)};
    font-weight: 700;
    line-height: 1.4;

    @media screen and (${STYLE.MEDIA.PC}) {
      padding-left: 88px;
      font-size: ${rem(28)};
    }

    &::before {
      content: counter(item);
      display: flex;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 1em;
      margin-top: auto;
      margin-bottom: auto;
      color: var(--color-text-key);
      font-family: var(--font-family-number);
      font-size: ${rem(38)};
      line-height: 1;
      counter-increment: item;
      aspect-ratio: 1 / 0.98;

      @media screen and (${STYLE.MEDIA.PC}) {
        width: 74px;
        font-size: ${rem(50)};
      }
    }
  }

  & > .body > .items > .item > .content > .title > .icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 54px;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;

    @media screen and (${STYLE.MEDIA.PC}) {
      width: 74px;
    }
  }

  & > .body > .items > .item > .content > .text {
    margin-top: 12px;
    font-size: ${rem(15)};
    font-weight: 400;

    @media screen and (${STYLE.MEDIA.PC}) {
      font-size: ${rem(16)};
    }
  }

  & > .body > .items > .item > .imagewrapper {
    position: relative;
    margin-right: -24px;
    margin-left: -24px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-basis: 40vw;
      margin-right: 0;
      margin-left: 0;
      overflow: hidden;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      flex-basis: 544px;
    }

    &::before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      background: var(--color-background-bright);
      aspect-ratio: 496 / 200;

      @media screen and (${STYLE.MEDIA.TABLET}) {
        border-radius: 8px;
      }
    }
  }

  & > .body > .items > .item > .imagewrapper > .image {
    display: block;
    width: 100%;
    height: auto;
  }

  & > .decoration {
    display: none;
    pointer-events: none;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      display: block;
      position: absolute;
      bottom: -280px;
      left: calc(50% - 740px);
      width: 128px;
      height: 762px;
    }
  }

  & > .decoration > .image {
    display: block;
    width: 100%;
    height: auto;
  }
`

const AdvantageTopComponent = () => {
  return (
    <div className={advantageTopStyle}>
      <div className="body">
        <h2 className="title">
          <span className="inner">
            <StaticImage className="logo" src="../../images/logo-only.png" placeholder="none" alt="ASHIATO" />
            3つ<span className="connector">の</span>メリット
          </span>
        </h2>
        <ul className="items">
          <li className="item">
            <div className="content">
              <h3 className="title" data-show-scroll="true">
                <IconLoupe className="icon icon-loupe" />
                ミスマッチ軽減により
                <br />
                早期離職を防止
              </h3>
              <p className="text" data-show-scroll="true">
                約15万社の採用支援実績・ノウハウをもとに、独自のアンケートを実施。第三者情報による客観情報と面接アドバイスにより、選考時の見極め精度向上に寄与します。
              </p>
            </div>
            <div className="imagewrapper">
              <StaticImage className="image" src="../../images/advantage01.png" placeholder="none" alt="" />
            </div>
          </li>
          <li className="item">
            <div className="content">
              <h3 className="title" data-show-scroll="true">
                <IconLoupe className="icon icon-loupe" />
                労務リスク可視化による
                <br />
                ガバナンス強化
              </h3>
              <p className="text" data-show-scroll="true">
                経歴や実績の詐称、過去の勤務実績の把握も可能。またコンプライアンスチェックにより、労務トラブルのリスクを減らします。上場に向けたガバナンス体制の強化も支援します。
              </p>
            </div>
            <div className="imagewrapper">
              <StaticImage className="image" src="../../images/advantage02.png" placeholder="none" alt="" />
            </div>
          </li>
          <li className="item">
            <div className="content">
              <h3 className="title" data-show-scroll="true">
                <IconLoupe className="icon icon-loupe" />
                入社者の活躍・定着を
                <br />
                支援
              </h3>
              <p className="text" data-show-scroll="true">
                これまで一緒に働いてきた仲間の声から、採用候補者の人柄や性格はもちろん、「パフォーマンスを引き出すマネジメント方法」など、入社後に役立つ情報も取得可能です。
              </p>
            </div>
            <div className="imagewrapper">
              <StaticImage className="image" src="../../images/advantage03.png" placeholder="none" alt="" />
            </div>
          </li>
        </ul>
      </div>
      <Parallax className="decoration" speed={5}>
        <StaticImage className="image" src="../../images/logo-text.png" loading="lazy" placeholder="none" alt="" />
      </Parallax>
    </div>
  )
}

export default AdvantageTopComponent
