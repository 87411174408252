import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import { Parallax } from 'react-scroll-parallax'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const thoughtTopStyle = css`
  position: relative;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 56px;
  padding-bottom: 48px;

  @media screen and (${STYLE.MEDIA.PC}) {
    padding-top: 64px;
    padding-bottom: 56px;
  }

  & > .body {
    position: relative;
    z-index: 2;
  }

  & > .body > .title {
    color: var(--color-text-bright);
    font-size: ${rem(34)};
    font-weight: 700;
    line-height: 1.2;
    text-align: center;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  & > .body > .title > .text1 {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    font-size: ${rem(34)};
    font-weight: 700;
  }

  & > .body > .textwrapper {
    margin-top: 24px;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 36px;
    }
  }

  & > .body > .textwrapper > .text {
    color: var(--color-text-bright);
    font-size: ${rem(16)};
    font-weight: 700;
    line-height: 2;

    @media screen and (${STYLE.MEDIA.PC}) {
      font-size: ${rem(18)};
    }

    & + .text {
      margin-top: 1em;
    }
  }

  & > .body > .imagewrapper {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }

  & > .body > .imagewrapper > .image {
    max-width: 400px;
  }

  & > .decoration {
    position: absolute;
    bottom: -24px;
    left: calc(50% - 80px);
    width: 320px;
    opacity: 0.1;
    pointer-events: none;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      bottom: -48px;
      left: calc(50% + 250px);
    }
  }

  & > .decoration > .logo {
    width: 320px;
    aspect-ratio: 308 / 304;
  }
`

const ThoughtTopComponent = () => {
  return (
    <section className={thoughtTopStyle}>
      <div className="body">
        <div className="title" data-show-scroll="true">
          <h2 className="text1">
            <StaticImage
              className="logo"
              src="../../images/logo-only-white.svg"
              objectFit="cover"
              objectPosition="center center"
              placeholder="none"
              alt="ASHIATO"
            />
            に
          </h2>
          込めた想い
        </div>
        <div className="textwrapper">
          <p className="text" data-show-scroll="true">
            従来のリファレンスチェックは、いわば「ネガティブチェック」の側面が非常に大きいものでした。しかしこれはあくまで入社前の見極めに留まり、候補者の入社後の活躍という本質的な価値にはつながらないのではないか。
          </p>
          <p className="text" data-show-scroll="true">
            そこで開発したのが『ASHIATO』。候補者の「活躍の足跡」を可視化して次のフィールドへ引き継ぎ、入社後の活躍・定着率向上まで寄与していく。
          </p>
          <p className="text" data-show-scroll="true">
            ASHIATOが実現したいのは、これからの「活躍の可能性」が見える世界です。よリ多くの人の活躍が、企業の成長に繋がる世の中を創っていく為に。
          </p>
        </div>
        <div className="imagewrapper">
          <StaticImage
            className="image"
            src="../../images/thought.png"
            objectFit="cover"
            objectPosition="center center"
            placeholder="none"
            alt=""
          />
        </div>
      </div>
      <Parallax className="decoration" speed={-2}>
        <StaticImage
          className="logo"
          src="../../images/logo-symbol-white.svg"
          objectFit="contain"
          objectPosition="center center"
          placeholder="none"
          alt=""
        />
      </Parallax>
    </section>
  )
}

export default ThoughtTopComponent
