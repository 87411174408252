import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import { Query } from '~/types/graphql-types'
import { STYLE } from '~/utils/constant'
import { getGatsbyImageData, rem } from '~/utils/helper'

const caseTopStyle = css`
  & > .body {
    max-width: var(--width-max-content);
    margin-right: auto;
    margin-left: auto;
  }

  & > .body > .title {
    ${STYLE.MIXIN.CONTENT_TITLE};
  }

  & > .body > .buttonwrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > .body > .buttonwrapper > .button {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 288px;
    margin-top: 32px;
    padding: 18px 16px;
    border: 1px solid var(--color-background-key);
    border-radius: 100px;
    background: var(--color-background-bright);
    font-weight: bold;
    text-decoration: none;
    text-indent: var(--font-letter-spacing);

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 48px;
    }
  }

  & > .body > .items {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    row-gap: 24px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-direction: row;
      column-gap: 24px;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 64px;
      column-gap: 32px;
    }
  }
`

const itemCaseTopStyle = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;

  @media screen and (${STYLE.MEDIA.TABLET}) {
    flex-basis: calc(100% / 3);
  }

  &.-soon {
    align-items: center;
    justify-content: center;
    min-height: 400px;
    border-radius: 16px;
    background: var(--color-background-bright);
    color: var(--color-text-grey400);
    font-size: ${rem(24)};
    font-weight: 700;
    line-height: 1.67;
    text-align: center;
  }

  & > .button {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-grow: 1;
    padding: 24px;
    padding-bottom: 68px;
    transition: var(--hover-button-transition);
    border-radius: 8px;
    background: var(--color-background-bright);
    color: var(--color-text);
    text-decoration: none;

    @media screen and (${STYLE.MEDIA.PC}) {
      padding-bottom: 72px;
    }

    &:hover {
      opacity: var(--hover-button-opacity);
    }
  }

  & > .button > .titlewrapper {
    display: grid;
    grid-column-gap: 24px;
    grid-row-gap: 8px;
    grid-template-columns: 120px 1fr;
    grid-template-rows: repeat(2, auto);

    @media screen and (${STYLE.MEDIA.TABLET}) {
      display: block;
    }
  }

  & > .button > .titlewrapper > .imagewrapper {
    grid-area: 1 / 1 / 3 / 2;
  }

  & > .button > .titlewrapper > .imagewrapper > .image {
    width: 100%;
    height: auto;
  }

  & > .button > .titlewrapper > .logowrapper {
    grid-area: 1 / 2 / 2 / 3;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      display: block;
      margin-top: 16px;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 24px;
    }
  }

  & > .button > .titlewrapper > .logowrapper > .logo {
    display: block;
    width: auto;
    height: 22px;
  }

  & > .button > .titlewrapper > .title {
    grid-area: 2 / 2 / 3 / 3;
    color: var(--color-text-sub);
    font-size: ${rem(12)};
    font-weight: 400;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      margin-top: 8px;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      font-size: ${rem(13)};
    }
  }

  & > .button > .lead {
    flex-grow: 1;
    margin-top: 12px;
    font-size: ${rem(14)};
    font-weight: 700;

    @media screen and (${STYLE.MEDIA.PC}) {
      font-size: ${rem(15)};
    }
  }

  & > .button > .details {
    display: flex;
    align-items: center;
    margin-top: 12px;
    column-gap: 1em;
    font-size: ${rem(12)};

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      flex-direction: row;
      align-items: center;
      font-size: ${rem(13)};
    }
  }

  & > .button > .details > .title {
    font-weight: 700;
  }

  & > .button > .dummybutton {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 24px;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 10em;
    margin-right: auto;
    margin-left: auto;
    padding-top: 4px;
    padding-bottom: 4px;
    border: 1px solid var(--color-background-key);
    border-radius: 100px;
    background: var(--color-background-bright);
    color: var(--color-text-key);
    font-size: ${rem(11)};
    font-weight: 700;

    @media screen and (${STYLE.MEDIA.PC}) {
      font-size: ${rem(14)};
    }
  }
`

const CaseTopComponent = () => {
  const data: Query = useStaticQuery(
    graphql`
      {
        allFile(filter: { name: { eq: "cms-case" } }) {
          edges {
            node {
              childrenImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
              url
              name
              id
            }
          }
        }
        allMicrocmsStgCasePickup {
          edges {
            node {
              case {
                id
                image_main {
                  url
                }
                logo {
                  url
                }
                name
                number
                outline
                title
              }
            }
          }
        }
      }
    `,
  )

  return (
    <div className={caseTopStyle}>
      <div className="body">
        <h2 className="title">導入事例</h2>
        <ul className="items">
          {data &&
            data.allMicrocmsStgCasePickup.edges[0] &&
            data.allMicrocmsStgCasePickup.edges[0].node &&
            data.allMicrocmsStgCasePickup.edges[0].node.case &&
            data.allMicrocmsStgCasePickup.edges[0].node.case.map((item) => {
              if (!item || !item.name || !item.image_main || !item.image_main.url || !item.logo || !item.logo.url)
                return

              const gatsbyImageData = getGatsbyImageData(data.allFile, item.image_main.url)
              const gatsbyImageLogoData = getGatsbyImageData(data.allFile, item.logo.url)
              return (
                <li className={itemCaseTopStyle} key={item.id}>
                  <Link className="button" to={`/case/${item.id}`}>
                    <div className="titlewrapper">
                      <div className="imagewrapper">
                        <GatsbyImage className="image" image={gatsbyImageData} alt={item.name} />
                      </div>
                      <div className="logowrapper">
                        <GatsbyImage
                          className="logo"
                          image={gatsbyImageLogoData}
                          alt={item.name}
                          objectFit="contain"
                          objectPosition="0% 50%"
                        />
                      </div>
                      <h3 className="title">{item.name}</h3>
                    </div>
                    <dl className="details">
                      <dt className="title">従業員数</dt>
                      <dd className="text">{item.number}</dd>
                    </dl>
                    <p className="lead">{item.title}</p>
                    <p className="dummybutton">記事を読む</p>
                  </Link>
                </li>
              )
            })}
        </ul>
        <div className="buttonwrapper">
          <Link className="button" to="/case">
            もっとみる
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CaseTopComponent
