import React from 'react'
import { Link } from 'gatsby'
import { css } from 'linaria'
import IconCheck from '~/images/icon/check.svg'
import IconFixedAmount from '~/images/icon/fixed-amount.svg'
import IconTickets from '~/images/icon/tickets.svg'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const priceTopStyle = css`
  & > .body {
    max-width: var(--width-max-content);
    margin-right: auto;
    margin-left: auto;
  }

  & > .body > .title {
    ${STYLE.MIXIN.CONTENT_TITLE};
  }

  & > .body > .text {
    margin-top: 16px;
    padding-left: var(--font-letter-spacing);
    font-size: ${rem(16)};
    font-weight: 400;
    text-align: center;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 32px;
    }
  }

  & > .body > .items {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    row-gap: 24px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-direction: row;
      column-gap: 24px;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 64px;
      column-gap: 32px;
      row-gap: 32px;
    }
  }
`

const priceItemTopStyle = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px;
  overflow: hidden;
  border-radius: 8px;
  background: var(--color-background-bright);

  @media screen and (${STYLE.MEDIA.TABLET}) {
    flex-basis: 0;
  }

  @media screen and (${STYLE.MEDIA.PC}) {
    padding: 32px;
  }

  & > .title {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    font-size: ${rem(20)};
    font-weight: 700;
    text-align: center;

    @media screen and (${STYLE.MEDIA.PC}) {
      column-gap: 16px;
    }
  }

  & > .title .iconwrapper {
    position: relative;
    width: 48px;
    height: 48px;

    @media screen and (${STYLE.MEDIA.PC}) {
      width: 64px;
      height: 64px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      border-radius: 50%;
      background: var(--color-gradient-key);
    }
  }

  & > .title .iconwrapper > .icon {
    position: relative;
    z-index: 2;
    width: 100%;
    height: auto;
  }

  & > .text {
    margin-top: 24px;
  }

  & > .breakdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    padding: 24px 16px;
    border-top: 1px solid var(--color-border-black);
    border-bottom: 1px solid var(--color-border-black);
    font-weight: 700;

    @media screen and (${STYLE.MEDIA.PC}) {
      padding-right: 56px;
      padding-left: 56px;
    }
  }

  & > .breakdown > .title {
    padding: 4px 12px;
    border-radius: 100px;
    background-color: var(--color-background-key);
    color: var(--color-text-bright);
    font-size: ${rem(14)};
    font-weight: 700;
    white-space: nowrap;
  }

  & > .breakdown > .body {
    position: relative;
    top: -0.05em;
    color: var(--color-text-key);
    font-size: ${rem(20)};
    letter-spacing: 0.1em;
    line-height: 1;

    @media screen and (${STYLE.MEDIA.PC}) {
      font-size: ${rem(25)};
    }

    &.-price {
      font-size: ${rem(28)};
      letter-spacing: 0;

      @media screen and (${STYLE.MEDIA.TABLET}) {
        letter-spacing: 0.05em;
      }

      @media screen and (${STYLE.MEDIA.PC}) {
        font-size: ${rem(30)};
        letter-spacing: 0.1em;
      }
    }
  }

  & > .contents {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 24px;
    row-gap: 12px;

    @media screen and (${STYLE.MEDIA.PC}) {
      padding-right: 24px;
      padding-left: 24px;
    }
  }

  & > .contents > .content {
    position: relative;
    padding-left: 1.8em;
    font-size: ${rem(16)};
    font-weight: 700;
  }

  & > .contents > .content > .iconwrapper {
    display: flex;
    position: absolute;
    top: 0.15em;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 1.3em;
    height: 1.3em;
    overflow: hidden;
    border-radius: 50%;
    background: var(--color-background-key);
  }

  & > .contents > .content > .iconwrapper > .icon {
    width: ${rem(16)};
    color: var(--color-text-bright);
    line-height: 1;

    & path {
      fill: var(--color-text-bright);
    }
  }

  & > .footer > .buttonwrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > .footer > .buttonwrapper > .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 32px;
    padding: 18px 16px;
    border-radius: 100px;
    background: var(--color-background-key);
    color: var(--color-text-bright);
    font-weight: 700;
    text-decoration: none;
    text-indent: var(--font-letter-spacing);

    @media screen and (${STYLE.MEDIA.PC}) {
      min-width: 288px;
    }
  }
`

const PriceTopComponent = () => {
  return (
    <div className={priceTopStyle}>
      <div className="body">
        <h2 className="title">料金プラン</h2>
        <p className="text">料金は税別となっております</p>
        <div className="items">
          <section className={priceItemTopStyle}>
            <h3 className="title">
              <span className="iconwrapper">
                <IconTickets className="icon" />
              </span>
              チケットプラン
            </h3>
            <p className="text">
              採用人数に合わせてサービスをご利用いただけます。
              <br />
              月によって選考状況が変わるような企業様にご導入いただいております。
            </p>
            <dl className="breakdown">
              <dt className="title">利用費</dt>
              <dd className="body -price">¥30,000〜/人</dd>
            </dl>
            <ul className="contents">
              <li className="content">
                <div className="iconwrapper">
                  <IconCheck className="icon" />
                </div>
                導入コンサルティング
              </li>
              <li className="content">
                <div className="iconwrapper">
                  <IconCheck className="icon" />
                </div>
                運用サポート
              </li>
            </ul>
            <div className="footer">
              <div className="buttonwrapper">
                <Link className="button" to="/inquiry">
                  お問い合わせ
                </Link>
              </div>
            </div>
          </section>
          <section className={priceItemTopStyle}>
            <h3 className="title">
              <span className="iconwrapper">
                <IconFixedAmount className="icon" />
              </span>
              定額課金プラン
            </h3>
            <p className="text">
              定期的に振り返りのミーティングを実施。運用フローの改善・分析を行うことで、より良い採用活動のコンサルティングをさせて頂きます。
            </p>
            <dl className="breakdown">
              <dt className="title">利用費</dt>
              <dd className="body">お問い合せ下さい</dd>
            </dl>
            <ul className="contents">
              <li className="content">
                <div className="iconwrapper">
                  <IconCheck className="icon" />
                </div>
                導入コンサルティング
              </li>
              <li className="content">
                <div className="iconwrapper">
                  <IconCheck className="icon" />
                </div>
                運用サポート
              </li>
              <li className="content">
                <div className="iconwrapper">
                  <IconCheck className="icon" />
                </div>
                結果分析コンサルティング
              </li>
              <li className="content">
                <div className="iconwrapper">
                  <IconCheck className="icon" />
                </div>
                定期的に振り返りのミーティングを実施し、個社ごとに沿った最適な運用フローをご提示
              </li>
              <li className="content">
                <div className="iconwrapper">
                  <IconCheck className="icon" />
                </div>
                レポート結果から個社ごとの特徴を分析、今後の採用活動へのコンサルティングを実施
              </li>
            </ul>
            <div className="footer">
              <div className="buttonwrapper">
                <Link className="button" to="/inquiry">
                  お問い合わせ
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default PriceTopComponent
