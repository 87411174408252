import React from 'react'
import { css } from 'linaria'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from '~/utils/helper'
import { STYLE } from '~/utils/constant'
import { Query } from '~/types/graphql-types'

const partnerTopStyle = css`
  padding-top: 44px;
  padding-bottom: 16px;
  overflow: hidden;
  background: var(--color-background-bright);

  --width-banner-mobile: 115px;
  --width-banner-tablet: 180px;

  @media screen and (${STYLE.MEDIA.TABLET}) {
    padding-top: 72px;
    padding-bottom: 48px;
  }

  @keyframes scroll-banner {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-50%);
    }
  }

  & > .body {
    display: flex;
    width: calc((var(--width-banner-mobile) + 12px) * var(--data-partner-colmums-mobile) * 2);
    animation: scroll-banner 60s linear -55s infinite;
    will-change: transform;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      width: calc((var(--width-banner-tablet) + 24px) * var(--data-partner-colmums-tablet) * 2);
    }

    & > .items {
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      width: calc((var(--width-banner-mobile) + 12px) * var(--data-partner-colmums-mobile));
      height: 64px;
      row-gap: 12px;

      @media screen and (${STYLE.MEDIA.TABLET}) {
        width: calc((var(--width-banner-tablet) + 24px) * var(--data-partner-colmums-tablet));
        height: 100px;
        animation-duration: 24s;
        animation-delay: -4s;
        row-gap: 24px;
      }
    }

    & > .items > .item {
      width: var(--width-banner-mobile);
      aspect-ratio: 180 / 38;
      margin-right: 12px;

      @media screen and (${STYLE.MEDIA.TABLET}) {
        width: var(--width-banner-tablet);
        margin-right: 24px;
      }
    }

    & > .items > .item > .logo {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    & > .items > .item > .text {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: var(--color-grey600);
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.1em;
      line-height: 32px;

      @media screen and (${STYLE.MEDIA.TABLET}) {
        line-height: 42px;
      }

      @media screen and (${STYLE.MEDIA.PC}) {
        line-height: 46px;
      }
    }
  }
`

const PartnerTopComponent = () => {
  const data: Query = useStaticQuery(
    graphql`
      {
        allFile(filter: { name: { eq: "cms-partner-logo" } }) {
          edges {
            node {
              childrenImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
              url
              id
              name
            }
          }
        }
        allMicrocmsPartnerLogo {
          edges {
            node {
              alt
              id
              partner_logo {
                url
              }
            }
          }
        }
      }
    `,
  )

  const mobilecolmums: number = Math.ceil(data.allMicrocmsPartnerLogo.edges.length / 2)
  const tabletcolmums: number = Math.ceil(data.allMicrocmsPartnerLogo.edges.length / 2)
  const dataColmums = {
    '--data-partner-colmums-mobile': mobilecolmums,
    '--data-partner-colmums-tablet': tabletcolmums,
  }

  const partnerItems = data.allMicrocmsPartnerLogo.edges.map((edge) => {
    const item = edge.node
    if (!item.partner_logo || !item.partner_logo.url) return
    const gatsbyImageData = getGatsbyImageData(data.allFile, item.partner_logo.url)
    return (
      <li className="item" key={item.alt}>
        <GatsbyImage className="logo" image={gatsbyImageData} loading="eager" alt={item.alt} objectFit="contain" />
      </li>
    )
  })

  return (
    <div className={partnerTopStyle}>
      <div className="body" style={dataColmums}>
        {/* Note: 繰り返し用に同じ要素を2つ並べる */}
        <ul className="items">{partnerItems}</ul>
        <ul className="items">{partnerItems}</ul>
      </div>
    </div>
  )
}

export default PartnerTopComponent
