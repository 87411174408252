import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const reasonTopStyle = css`
  & > .body {
    max-width: var(--width-max-content);
    margin-right: auto;
    margin-left: auto;
  }

  & > .body > .title {
    ${STYLE.MIXIN.CONTENT_TITLE};
  }

  & > .body > .lead {
    margin-top: 24px;
    font-size: ${rem(16)};
    font-weight: 700;
    text-align: center;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 32px;
      font-size: ${rem(18)};
    }
  }

  & > .body > .items {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    row-gap: 40px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-direction: row;
      margin-top: 80px;
      column-gap: 16px;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      column-gap: 32px;
    }
  }

  & > .body > .items > .item {
    display: flex;
    position: relative;
    flex-basis: 0;
    flex-direction: column;
    flex-grow: 1;
    padding: 32px 24px 24px;
    border-radius: 8px;
    background: var(--color-background-bright);

    &::before {
      content: 'POINT ' attr(data-count);
      display: flex;
      position: absolute;
      top: -14px;
      right: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      width: 104px;
      height: 28px;
      margin-right: auto;
      margin-left: auto;
      border-radius: 100px;
      background: var(--color-background-key);
      color: var(--color-text-bright);
      font-family: var(--font-family-number);
      font-size: ${rem(13)};
      font-weight: 700;
      white-space: nowrap;

      @media screen and (${STYLE.MEDIA.PC}) {
        top: -15px;
        width: 112px;
        height: 30px;
        font-size: ${rem(15)};
      }
    }
  }

  & > .body > .items > .item > .title {
    margin-top: 24px;
    font-size: ${rem(20)};
    font-weight: 700;
    text-align: center;
  }

  & > .body > .items > .item > .imagewrapper {
    position: relative;
    order: -1;
    width: 100%;
    aspect-ratio: 520 / 347;
    margin-right: auto;
    margin-left: auto;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 8px;
      opacity: 0.05;
      background: var(--color-background-key);
    }
  }

  & > .body > .items > .item > .imagewrapper > .image {
    display: block;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  & > .body > .items > .item > .text {
    margin-top: 16px;
    font-weight: 400;
  }
`

const ReasonTopComponent = () => {
  return (
    <div className={reasonTopStyle}>
      <div className="body">
        <h2 className="title">
          ASHIATOが
          <br className="_mobile-inline" />
          選ばれる理由
        </h2>
        <p className="lead">
          初めてリファレンスチェックを
          <br className="_mobile-inline" />
          導入する企業も安心です
        </p>
        <ul className="items">
          <li className="item" data-count="01">
            <h3 className="title">
              約15万社の採用支援実績を
              <br />
              通じた独自コンテンツ
            </h3>
            <div className="imagewrapper">
              <StaticImage className="image" src="../../images/reason01.png" placeholder="none" alt="" />
            </div>
            <p className="text">
              豊富な採用支援実績と産学連携をもとにした独自のアンケートを実施。適性テストやコンディションサーベイなどのツールと組み合わせた入社後活躍の総合支援も可能です。
            </p>
          </li>
          <li className="item" data-count="02">
            <h3 className="title">
              採用候補者がポジティブに
              <br />
              捉えるサービス設計
            </h3>
            <div className="imagewrapper">
              <StaticImage className="image" src="../../images/reason02.png" placeholder="none" alt="" />
            </div>
            <p className="text">
              双方のミスマッチをなくし、これまでの活躍を自己PRできるツールとして採用候補者へ紹介。志望度を下げること無く活用が可能なUIUXを追及しています。
            </p>
          </li>
          <li className="item" data-count="03">
            <h3 className="title">
              上場会社運営の安心感による
              <br />
              回答ハードルの低減
            </h3>
            <div className="imagewrapper">
              <StaticImage className="image" src="../../images/reason03.png" placeholder="none" alt="" />
            </div>
            <p className="text">
              プライム上場のエン・ジャパンという認知度により、採用候補者のレポート回収に向けた心理的ハードルを軽減します。
            </p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ReasonTopComponent
