import React, { useRef, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import { Parallax } from 'react-scroll-parallax'
import IconPlay from '~/images/icon/play.svg'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const aboutTopStyle = css`
  & > .body {
    position: relative;
    z-index: 3;
    max-width: var(--width-max-content);
    margin-right: auto;
    margin-left: auto;
    padding: 1px 32px 48px;
    border-radius: 8px;
    background: var(--color-background-bright);

    @media screen and (${STYLE.MEDIA.PC}) {
      padding-right: 56px;
      padding-bottom: 56px;
      padding-left: 56px;
    }
  }

  & > .body > .section > .title {
    margin-top: 32px;
    font-size: ${rem(20)};
    font-weight: 700;
    text-align: center;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 56px;
      font-size: ${rem(28)};
    }
  }

  & > .body > .section > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-direction: row;
      column-gap: 32px;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 40px;
      column-gap: 40px;
    }
  }

  & > .body > .section > .content > .image {
    margin-right: -32px;
    margin-left: -32px;
    aspect-ratio: 686 / 386;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      margin-right: 0;
      margin-left: 0;
      border-radius: 8px;
    }
  }

  & > .body > .section > .content > .textwrapper {
    margin-top: 24px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-basis: 50%;
      min-width: 50%;
      margin-top: 0;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      flex-basis: 422px;
      min-width: 422px;
    }
  }

  & > .body > .section > .content > .textwrapper > .text {
    font-size: ${rem(15)};
    line-height: 1.75;

    @media screen and (${STYLE.MEDIA.PC}) {
      font-size: ${rem(16)};
    }
  }

  & > .body > .section > .content > .textwrapper > .text + .text {
    margin-top: 1em;
  }

  & > .body > .section > .buttonwrapper {
    display: flex;
    justify-content: center;
    margin-top: 24px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      margin-top: 32px;
    }
  }

  & > .body > .section > .buttonwrapper > .button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    column-gap: 8px;
    border: 1px solid var(--color-border-key);
    border-radius: 100px;
    background: none;
    font-size: ${rem(16)};
    font-weight: 700;
  }

  & > .body > .section > .buttonwrapper > .button > .icon {
    width: 24px;
    height: auto;
  }

  & > .decoration {
    display: none;
    pointer-events: none;

    @media screen and (${STYLE.MEDIA.PC}) {
      display: block;
      position: absolute;
      z-index: 2;
      bottom: -280px;
      left: calc(50% + 400px);
      width: 320px;
      height: 320px;
    }
  }

  & > .decoration > .image {
    display: block;
    width: 100%;
    height: auto;
  }
`

const modalVideoPlayerStyle = css`
  & > .modal {
    display: flex;
    visibility: hidden;
    position: fixed;
    z-index: var(--zindex-modal);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    transition: opacity 0.48s var(--transition-md) 0.24s;
    opacity: 0;
    pointer-events: none;
    will-change: opacity;

    &.is-show {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }
  }

  & > .modal > .body {
    display: flex;
    position: relative;
    z-index: 2;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 98vw;
    transform: translateY(-16px);
    opacity: 0;

    @media screen and (${STYLE.MEDIA.PC}) {
      max-width: 80vw;
    }

    @media (min-aspect-ratio: 16 / 9) {
      max-width: 150vh;
    }
  }

  & > .modal.is-show > .body {
    transform: translateY(0);
    transition: all 0.48s var(--transition-md) 0.48s;
    opacity: 1;
  }

  & > .modal > .body > .videowrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
  }

  & > .modal > .body > .videowrapper > .video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > .modal > .background {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    border: 0;
    opacity: 0.7;
    background: var(--color-background-black);
  }
`

const AboutTopComponent = () => {
  const [isShowModal, setIsShowModal] = useState(false)
  const [isVideoLoaded, setIsVideoLoaded] = useState(false)
  const refVideo = useRef<HTMLIFrameElement>(null)

  const videoControl = (action: string) => {
    if (!refVideo.current || !refVideo.current.contentWindow) return
    refVideo.current.contentWindow.postMessage('{"event":"command","func":"' + action + '","args":""}', '*')
  }

  const showModal = () => {
    setIsShowModal(true)
    if (!isVideoLoaded && refVideo.current) {
      // Note: 初期ロードが重くなるので、初期表示時にiframeのdata-srcをsrcに渡す
      const iframe: HTMLIFrameElement = refVideo.current
      iframe.dataset.src && iframe.setAttribute('src', iframe.dataset.src)
      iframe.onload = () => {
        setIsVideoLoaded(true)
        videoControl('playVideo')
      }
    } else {
      videoControl('playVideo')
    }
  }

  const hideModal = () => {
    setIsShowModal(false)
    videoControl('pauseVideo')
  }

  return (
    <div className={aboutTopStyle}>
      <div className="body">
        <section className="section">
          <h2 className="title" data-show-scroll="true">
            「リファレンスチェック」とは？
          </h2>
          <div className="content">
            <StaticImage
              className="image"
              objectFit="contain"
              src="../../images/about.png"
              loading="lazy"
              placeholder="none"
              alt="「リファレンスチェック」とは？"
            />
            <div className="textwrapper" data-show-scroll="true">
              <p className="text">
                リファレンスチェックとは、経歴照会や身元照会と訳され、採用候補者とこれまで一緒に仕事をしてきた第三者（前職の上司・同僚など）に、候補者の前職での働きぶりや人柄について、採用活動の過程で確認をとることを指します。
              </p>
              <p className="text">
                海外では約95%の企業が利用する一般的な採用プロセスであり、採用候補者に関する客観的な情報を取得できる点で注目を集め、昨今、日本でも注目されています。
              </p>
            </div>
          </div>
          <div className="buttonwrapper" data-show-scroll="true">
            <button className="button" type="button" onClick={showModal}>
              <IconPlay className="icon icon-play" />
              動画で詳しく見る
            </button>
          </div>
        </section>
      </div>
      <Parallax className="decoration" speed={-10}>
        <StaticImage className="image" src="../../images/logo-symbol.png" loading="lazy" placeholder="none" alt="" />
      </Parallax>

      <aside className={modalVideoPlayerStyle}>
        <div className={isShowModal ? 'modal is-show' : 'modal'}>
          <div className="body">
            <div className="videowrapper">
              <iframe
                width="560"
                height="315"
                data-src="https://www.youtube.com/embed/VhQ_DmOa6WY?enablejsapi=1&rel=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="video"
                ref={refVideo}
              />
            </div>
          </div>
          <button className="background" type="button" onClick={hideModal} />
        </div>
      </aside>
    </div>
  )
}

export default AboutTopComponent
