import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import FunctionCompliance from '~/components/Top/FunctionCompliance'
import FunctionOther from '~/components/Top/FunctionOther'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const functionTopStyle = css`
  & > .body {
    max-width: var(--width-max-content);
    margin-right: auto;
    margin-left: auto;
  }

  & > .body > .title {
    ${STYLE.MIXIN.CONTENT_TITLE};
  }

  & > .body > .lead {
    margin-top: 24px;
    font-size: ${rem(16)};
    font-weight: 700;
    text-align: center;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      margin-top: 32px;
      font-size: ${rem(18)};
    }
  }

  & > .body > .items {
    margin-top: 8px;
    margin-right: -24px;
    margin-left: -24px;
    padding-bottom: 58px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-flow: row wrap;
      margin-top: 16px;
      margin-right: 0;
      margin-left: 0;
      padding-bottom: 0;
    }
  }

  & > .body > .items .slick-slide > div {
    @media screen and (${STYLE.MEDIA.TABLET}) {
      display: flex;
      column-gap: 32px;
    }
  }

  & > .body > .items .slick-slide > div > .item {
    margin-top: 64px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      border-radius: 8px;
      background: var(--color-background-bright);
    }
  }

  & > .body > .items .slick-slide > div > .item > .body {
    position: relative;
    margin-right: 24px;
    margin-left: 24px;
    padding: 40px 24px 24px;
    border-top: 206px solid var(--color-background-bluegray100);
    border-radius: 8px;
    background: var(--color-background-bright);

    @media screen and (${STYLE.MEDIA.TABLET}) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  & > .body > .items .slick-slide > div > .item > .body > .title {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    font-size: ${rem(20)};
    font-weight: 700;
    text-align: center;

    @media screen and (${STYLE.MEDIA.PC}) {
      font-size: ${rem(24)};
    }

    &::before {
      content: attr(data-count);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.8em;
      border-radius: 50%;
      background-color: var(--color-background-key);
      color: var(--color-text-bright);
      font-family: var(--font-family-number);
      font-size: ${rem(15)};
      aspect-ratio: 1 / 1;

      @media screen and (${STYLE.MEDIA.PC}) {
        font-size: ${rem(16)};
      }
    }
  }

  & > .body > .items .slick-slide > div > .item > .body > .text {
    margin-top: 12px;
  }

  & > .body > .items .slick-slide > div > .item > .body > .imagewrapper {
    position: absolute;
    top: -224px;
    right: 0;
    left: 2px;
    width: 180px;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    aspect-ratio: 378 / 528;

    @media screen and (${STYLE.MEDIA.PC}) {
      left: 4px;
    }
  }

  & > .body > .items .slick-arrow {
    display: block;
    position: absolute;
    bottom: 0;
    width: 42px;
    height: 42px;
    padding: 0;
    overflow: hidden;
    border: 0;
    border-radius: 50%;
    background: var(--color-background-bright);
    text-indent: 100%;
    white-space: nowrap;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 12px;
      height: 3px;
      margin: auto;
      transform-origin: 1.5px center;
      border-radius: 100px;
      background: var(--color-background-key);
    }

    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }

  & > .body > .items .slick-arrow.slick-prev {
    left: 88px;
  }

  & > .body > .items .slick-arrow.slick-next {
    right: 88px;
    transform: rotate(180deg);
  }

  & > .body > .items .slick-dots {
    position: absolute;
    right: 0;
    bottom: 8px;
    left: 0;
    width: 112px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }

  & > .body > .items .slick-dots > li {
    display: inline-block;
    margin-right: 8px;
    margin-left: 8px;
  }

  & > .body > .items .slick-dots > li > button {
    display: block;
    width: 12px;
    height: 12px;
    padding: 0;
    overflow: hidden;
    border: 0;
    border-radius: 50%;
    background-color: var(--color-background-bluegray100);
    text-indent: 100%;
    white-space: nowrap;
  }

  & > .body > .items .slick-dots > .slick-active > button {
    background-color: var(--color-background-key);
  }

  & > .body > .subsection {
    margin-top: 64px;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 96px;
    }
  }
`

const FunctionTopComponent = () => {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 2,
    slidesPerRow: 2,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
          slidesPerRow: 1,
          dots: true,
        },
      },
    ],
  }

  return (
    <div className={functionTopStyle}>
      <div className="body">
        <h2 className="title">ASHIATOの機能</h2>
        <p className="lead">
          ASHIATOで取得できるリファレンス
          <br className="_mobile-inline" />
          レポートのイメージです
        </p>
        <Slider {...settings} className="items">
          <div className="item">
            <dl className="body">
              <dt className="title" data-count="1">
                他己分析レビュー
              </dt>
              <dd className="text">
                115万人以上の受検データを持つエン・ジャパンの適性検査からノウハウを抽出し、推薦者が回答する独自の『他己分析テスト』結果を提供。
              </dd>
              <dd className="imagewrapper">
                <StaticImage
                  className="image"
                  src="../../images/function01.png"
                  placeholder="none"
                  alt="他己分析レビュー"
                />
              </dd>
            </dl>
          </div>
          <div className="item">
            <dl className="body">
              <dt className="title" data-count="2">
                候補者の特徴やアドバイス
              </dt>
              <dd className="text">
                他己分析レビューから『採用候補者の特徴』を把握し、30年蓄積した採用支援のノウハウから『面接ポイント』や『入社後のフォローポイント』を候補者ごとに提供。
              </dd>
              <dd className="imagewrapper">
                <StaticImage
                  className="image"
                  src="../../images/function02.png"
                  placeholder="none"
                  alt="候補者の特徴やアドバイス"
                />
              </dd>
            </dl>
          </div>
          <div className="item">
            <dl className="body">
              <dt className="title" data-count="3">
                職場態度
              </dt>
              <dd className="text">
                採用時に把握したい懸念要素を『コンプライアンス遵守』『ハラスメント傾向』『勤務態度』の観点で推薦者に確認。
              </dd>
              <dd className="imagewrapper">
                <StaticImage className="image" src="../../images/function03.png" placeholder="none" alt="職場態度" />
              </dd>
            </dl>
          </div>
          <div className="item">
            <dl className="body">
              <dt className="title" data-count="4">
                アンケートの回答
              </dt>
              <dd className="text">
                企業が自由に設定できる質問に対し、推薦者が回答した内容を表示。これまでの採用支援実績からエン・ジャパンが独自設計した質問テンプレートもご用意。
              </dd>
              <dd className="imagewrapper">
                <StaticImage
                  className="image"
                  src="../../images/function04.png"
                  placeholder="none"
                  alt="アンケートの回答"
                />
              </dd>
            </dl>
          </div>
        </Slider>

        <section className="subsection">
          <FunctionCompliance />
        </section>

        <section className="subsection">
          <FunctionOther />
        </section>
      </div>
    </div>
  )
}

export default FunctionTopComponent
