import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import { Parallax } from 'react-scroll-parallax'
import SmoothScroll from '~/components/Common/SmoothScroll'
import IconDownload from '~/images/icon/download.svg'
import ImgLogo from '~/images/logo-only-white.svg'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const appealTopStyle = css`
  position: relative;
  max-width: var(--width-max-content);
  margin-right: auto;
  margin-left: auto;

  & > .title {
    color: var(--color-text-bright);
    font-size: ${rem(34)};
    font-weight: 700;
    line-height: 1.2;
    text-align: center;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: ${rem(36)};
    }
  }

  & > .title > .text1 {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
  }

  & > .body {
    margin-top: 32px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      column-gap: 32px;
    }
  }

  & > .body > .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 64px;
    margin-top: 24px;
    transition: var(--hover-button-transition);
    border: 1px solid var(--color-border-bright);
    border-radius: 100px;
    background: none;
    background: var(--color-background-bright);
    color: var(--color-text-bright);
    color: var(--color-text-link);
    font-size: ${rem(16)};
    font-weight: 700;
    text-decoration: none;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      max-width: 400px;
    }

    &:hover {
      opacity: var(--hover-button-opacity);
    }

    &.-request {
      column-gap: 8px;
      border-color: var(--color-background-yellow700);
      background: var(--color-background-yellow700);
    }
  }

  & > .body > .button.-request > .image {
    width: 80px;
    height: auto;
    margin-top: -16px;
    margin-right: 8px;
    margin-left: -32px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      width: 110px;
      margin-top: -24px;
    }
  }

  & > .decoration {
    position: absolute;
    top: 48px;
    left: calc(50% - 80px);
    width: 320px;
    opacity: 0.1;
    pointer-events: none;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      top: 0;
      left: calc(50% + 250px);
    }
  }

  & > .decoration > .logo {
    width: 320px;
    aspect-ratio: 308 / 304;
  }
`

const AppealTopComponent = () => {
  return (
    <div className={appealTopStyle}>
      <div className="title" data-show-scroll="true">
        <div className="text1">
          <img src={ImgLogo} className="image" alt="ASHIATO" />で
        </div>
        安心の採用を！
      </div>
      <div className="body" data-show-scroll="true">
        <SmoothScroll className="button -request" to="request" href="/#request">
          <StaticImage
            className="image"
            src="../../images/download.png"
            objectFit="cover"
            objectPosition="center center"
            placeholder="none"
            alt=""
          />
          資料ダウンロード
          <IconDownload className="icon" />
        </SmoothScroll>
        <Link className="button" to="/inquiry/">
          お問い合わせ
        </Link>
      </div>
      <Parallax className="decoration" speed={-2}>
        <StaticImage
          className="logo"
          src="../../images/logo-symbol-white.svg"
          objectFit="contain"
          objectPosition="center center"
          placeholder="none"
          alt=""
        />
      </Parallax>
    </div>
  )
}

export default AppealTopComponent
