import React from 'react'
import { css } from 'linaria'
import IconAlphabet from '~/images/icon/alphabet.svg'
import IconArrowTop from '~/images/icon/arrow-top.svg'
import IconAts from '~/images/icon/ats.svg'
import IconEye from '~/images/icon/eye.svg'
import IconProhibition from '~/images/icon/prohibition.svg'
import IconSecurity from '~/images/icon/security.svg'
import IconShare from '~/images/icon/share.svg'
import IconTemplate from '~/images/icon/template.svg'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const items = [
  {
    title: 'セキュリティ対策',
    text: '『プライバシーマークの取得』『定期的な脆弱性診断』『通信暗号化』『IPアドレス制限』『ログイン時の2段階認証』などのセキュリティ対策を実施。',
    icon: <IconSecurity className="icon" />,
  },
  {
    title: '独自設計の\n質問テンプレート',
    text: '企業で検討された質問だけでなく、これまでの採用支援実績からエン・ジャパンが独自設計した質問テンプレートをご用意。採用候補者に沿った質問を自由に設定可能。',
    icon: <IconTemplate className="icon" />,
  },
  {
    title: 'レポートシェア',
    text: '採用候補者が既に他社でASHIATOを利用されていた場合、取得済みのレポートをそのままご確認いただける機能。候補者への負担を軽減し、レポート回収期間を短縮。',
    icon: <IconShare className="icon" />,
  },
  {
    title: '閲覧権限の管理',
    text: '複数の企業アカウントを作成することができ、各役割に応じて編集・閲覧などの権限を設定可能。',
    icon: <IconEye className="icon" />,
  },
  {
    title: '英語対応',
    text: '採用候補者・推薦者の各ページおいて、英語への言語切り替えが可能になっており、設定された設問も英語表示可能。',
    icon: <IconAlphabet className="icon" />,
  },
  {
    title: 'なりすまし防止',
    text: '回答のなりすまし防止のため、推薦者の本人確認を実施。不正回答を自動検知し、企業様に随時ご報告。',
    icon: <IconProhibition className="icon" />,
  },
  {
    title: 'sonar ATS連携',
    text: '採用候補者への依頼から、レポートの結果の確認まで、sonar ATS上で一元管理が可能。',
    icon: <IconAts className="icon" />,
  },
]

const functionOtherTopStyle = css`
  @keyframes accordion {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  & > .title {
    font-size: ${rem(24)};
    font-weight: 700;
    text-align: center;

    @media screen and (${STYLE.MEDIA.PC}) {
      font-size: ${rem(32)};
    }
  }

  & > .items {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-flow: row wrap;
      align-items: flex-start;
      column-gap: 32px;
    }
  }

  & > .items > .item {
    display: flex;
    position: relative;
    flex-basis: 0;
    flex-direction: column;
    margin-top: 16px;
    padding: 16px 24px;
    border-radius: 8px;
    background: var(--color-background-bright);

    @media screen and (${STYLE.MEDIA.TABLET}) {
      min-width: calc(50% - 16px);
      margin-top: 32px;
    }
  }

  & > .items > .item > .title {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    column-gap: 12px;
    font-weight: 700;
    text-align: left;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      column-gap: 16px;
    }

    &::marker {
      display: none;
    }

    &:hover {
      opacity: var(--hover-button-opacity);
    }
  }

  & > .items > .item > .title > .iconwrapper {
    position: relative;
    width: 48px;
    height: 48px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      border-radius: 50%;
      background: var(--color-gradient-key);
    }
  }

  & > .items > .item > .title > .iconwrapper > .icon {
    position: relative;
    z-index: 2;
    width: 100%;
    height: auto;
  }

  & > .items > .item > .title > .arrow {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 1em;
    margin-top: auto;
    margin-bottom: auto;
    transform: rotateX(-180deg);
    transition: transform 0.24s var(--transition-md) 0s;
    font-size: ${rem(20)};
  }

  & > .items > .item > .title > .arrow path {
    fill: var(--color-text);
  }

  & > .items > .item[open] > .title > .arrow {
    transform: rotateX(0);
  }

  & > .items > .item > .body {
    position: relative;
    max-height: 0;
    overflow: hidden;
  }

  & > .items > .item[open] > .body {
    max-height: none;
  }

  & > .items > .item > .body > .text {
    padding-top: 16px;
    padding-bottom: 8px;
    transform: translateY(-100%);
    transition: all 0.24s var(--transition-md) 0s;
    opacity: 0;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      min-height: calc(3.8em + 16px + 24px);
    }
  }

  & > .items > .item[open] > .body > .text {
    animation: accordion 0.24s var(--transition-md) 0s both;
  }
`

const FunctionOtherTopComponent = () => {
  return (
    <div className={functionOtherTopStyle}>
      <h3 className="title">その他機能</h3>
      <div className="items">
        {items.map((item) => {
          const titles = item.title.split(/(\n)/).map((title, index) => {
            return (
              <React.Fragment key={index}>
                {title.match(/\n/) ? <br className="_mobile-inline" /> : title}
              </React.Fragment>
            )
          })
          return (
            <details className="item" key={item.title}>
              <summary className="title">
                <span className="iconwrapper">{item.icon}</span>
                {titles}
                <IconArrowTop className="arrow" />
              </summary>
              <div className="body">
                <p className="text">{item.text}</p>
              </div>
            </details>
          )
        })}
      </div>
    </div>
  )
}

export default FunctionOtherTopComponent
