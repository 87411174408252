import React from 'react'
import { css } from 'linaria'
import IconArrowTop from '~/images/icon/arrow-top.svg'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const qa = [
  {
    question: '候補者に有利な回答が返ってきませんか？',
    answer: [
      '候補者が推薦者を指定するため、候補者と一定の関係値がある方が回答することになります。よって候補者に有利な回答が行われるケースは完全には排除できません。ただし設問はこの前提を踏まえた上で設計しており、エン・ジャパンが培ってきたノウハウによって、推薦者の一方的な印象評価ではなく、候補者に関する事実情報や人物像を具体的に引き出せるようになっています。企業側はこれらの客観的な情報・事実情報を選考材料に用いることができます。',
    ],
  },
  {
    question: '候補者の辞退率はあがりませんか？',
    answer: [
      '数字上での辞退率は上がることがあります。一方でASHIATOを利用することによる辞退では、候補者がすでに選考過程で虚偽の情報を伝えているケースや志望度が高くなかったケースがほとんどとなっておりますので、必要のない面接を削減、集中すべき候補者へ工数を割くことができます。',
      'また、質問内容の特徴から候補者にあくまでも評価のプラス材料として、入社後により良い環境を整えるために実施するといったメリットをお伝えいただくことを推奨しております。',
    ],
  },
  {
    question: '運用は面倒ではありませんか？',
    answer: [
      '企業側で実施いただくのは、候補者の基本情報登録と、候補者への事前連絡のみとなります。ここまで約5分で完結します。他に特別なことをしていただく必要はありません。また回答も平均して3営業日以内には返ってきております。',
    ],
  },
  {
    question: '選考フローのどの段階で実施するのが良いですか？',
    answer: [
      '基本的に1次面接後、2次面接（最終面接）前の実施を推奨しています。候補者の面接や適性テストでは把握しきれなかった評価点をASHIATOで取得していただき、その上でジャッジしていただくことで、候補者の見極めをより良く実施できるからです。また、入社後にスムーズに活躍頂くための情報も多く収集しますので、内定者を対象に入社前に実施いただき、研修や現場配属に活用いただくことも可能です。',
    ],
  },
  {
    question: '推薦者はどうやって選べばよいですか？',
    answer: [
      'ASHIATOでは、推薦者は「上司」「同僚」「部下」「取引先」のカテゴリから選択することができます。業務やミッションに関しての理解がある上司、近い距離で候補者を見てきた同僚、それぞれからご回答いただくことを推奨していますが、候補者に「リファレンス依頼をしやすい関係値の方は誰か」を確認した上で検討されるとスムーズかと思われます。',
    ],
  },
  {
    question: '相性の良い職種や業種はありますか？',
    answer: [
      '特定の職種や業種に限らず、幅広いシーンでご活用いただいております。営業職や事務職はもちろん、バックオフィス、エンジニアといった専門職、流通・小売・サービス系など様々な業界・職種で実績がございます。',
    ],
  },
  {
    question: '個人情報保護法違反に該当しませんか？',
    answer: [
      '個人情報を提供していただく旨を候補者、推薦者に同意していただければ問題ございません。システム上、両者から同意をいただける設計にしておりますのでご安心ください。企業側が採用活動、配置先での人事活動以外で活用する場合は、同意内容には含まれていないため、候補者・推薦者に再度同意をいただく必要があります。',
    ],
  },
  {
    question: '推薦者（回答者）の信頼度は担保されますか？',
    answer: [
      'リファレンスに回答いただく推薦者の皆様には、ご本人に了承を得た上で、本人確認ができる情報を可能な限り提供いただくよう依頼しております。情報については事務局にて1件1件、本人確認が取れるか認証を行った上でレポーティングいたしますので、一定レベルの信頼度を担保することが可能です。',
    ],
  },
]

const faqTopStyle = css`
  @keyframes accordion {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  & > .body {
    max-width: var(--width-max-content);
    margin-right: auto;
    margin-left: auto;
  }

  & > .body > .title {
    ${STYLE.MIXIN.CONTENT_TITLE};
  }

  & > .body > .items {
    margin-top: 40px;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 64px;
    }
  }

  & > .body > .items > .item {
    overflow: hidden;
    border-radius: 8px;
    background: var(--color-background-bright);

    & + .item {
      margin-top: 16px;
    }
  }

  & > .body > .items > .item > .question {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    min-height: 5.3em;
    padding: 16px 48px 16px calc(20px + 2.5em);
    transition: var(--hover-button-transition);
    border: 0;
    border-radius: 8px;
    color: var(--color-text);
    font-weight: 700;
    list-style: none;
    text-align: left;

    &::marker {
      display: none;
    }

    &:hover {
      opacity: var(--hover-button-opacity);
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      padding-right: 64px;
      padding-left: calc(44px + 2.5em);
      font-size: ${rem(18)};
    }

    &::before {
      content: 'Q.';
      position: absolute;
      top: 24px;
      left: 16px;
      width: 1.2em;
      color: var(--color-text-key);
      font-family: var(--font-family-number);
      font-size: ${rem(27)};
      font-weight: 700;
      line-height: 1;

      @media screen and (${STYLE.MEDIA.PC}) {
        left: 36px;
        font-size: ${rem(34)};
      }
    }
  }

  & > .body > .items > .item > .question > .icon {
    position: absolute;
    top: 0;
    right: 18px;
    bottom: 0;
    height: 1em;
    margin-top: auto;
    margin-bottom: auto;
    transform: rotateX(-180deg);
    transition: transform 0.24s var(--transition-md) 0s;
    font-size: ${rem(20)};

    @media screen and (${STYLE.MEDIA.PC}) {
      right: 36px;
      font-size: ${rem(24)};
    }
  }

  & > .body > .items > .item > .question > .icon path {
    fill: var(--color-text);
  }

  & > .body > .items > .item[open] > .question > .icon {
    transform: rotateX(0);
  }

  & > .body > .items > .item > .answer {
    position: relative;
    max-height: 0;
    overflow: hidden;
  }

  & > .body > .items > .item[open] > .answer {
    max-height: none;
  }

  & > .body > .items > .item > .answer > .content {
    padding: 0 24px 24px calc(20px + 2.5em);
    transform: translateY(-100%);
    transition: all 0.24s var(--transition-md) 0s;
    opacity: 0;
    font-weight: 400;
    line-height: 1.68;

    @media screen and (${STYLE.MEDIA.PC}) {
      padding-right: 48px;
      padding-left: calc(48px + 2.5em);
    }

    &::before {
      content: 'A.';
      position: absolute;
      top: -4px;
      left: 20px;
      width: 1.2em;
      color: var(--color-text-sub);
      color: var(--color-text-key);
      font-family: var(--font-family-number);
      font-size: ${rem(24)};
      font-weight: 700;

      @media screen and (${STYLE.MEDIA.PC}) {
        left: 37px;
        font-size: ${rem(34)};
        line-height: 1;
      }
    }
  }

  & > .body > .items > .item[open] > .answer > .content {
    animation: accordion 0.24s var(--transition-md) 0s both;
  }
`

const FaqTopComponent = () => {
  return (
    <div className={faqTopStyle}>
      <div className="body">
        <h2 className="title">FAQ</h2>
        <div className="items">
          {qa.map((item) => {
            const answers = item.answer.map((answer) => {
              return (
                <p className="text" key={answer}>
                  {answer}
                </p>
              )
            })
            return (
              <details
                className="item"
                itemScope
                itemProp="mainEntity"
                itemType="https://schema.org/Question"
                key={item.question}
              >
                <summary className="question" itemProp="name">
                  <IconArrowTop className="icon" />
                  {item.question}
                </summary>
                <div className="answer" itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                  <div className="content" itemProp="text">
                    {answers}
                  </div>
                </div>
              </details>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default FaqTopComponent
