import React from 'react'
import { css } from 'linaria'
import { StaticImage } from 'gatsby-plugin-image'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const supportTopStyle = css`
  & > .body {
    max-width: var(--width-max-content);
    margin-right: auto;
    margin-left: auto;
  }

  & > .body > .title {
    ${STYLE.MIXIN.CONTENT_TITLE};
  }

  & > .body > .items {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    row-gap: 24px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-flow: row wrap;
      margin-top: 64px;
      column-gap: 32px;
      row-gap: 32px;
    }
  }

  & > .body > .items > .item {
    position: relative;
    flex-basis: 0;
    flex-grow: 1;
    padding: 24px;
    border-radius: 8px;
    background: var(--color-background-bright);

    @media screen and (${STYLE.MEDIA.TABLET}) {
      min-width: calc(50% - 16px);
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      padding: 32px;
    }
  }

  & > .body > .items > .item > .title {
    font-size: ${rem(20)};
    font-weight: 700;
    text-align: center;

    @media screen and (${STYLE.MEDIA.PC}) {
      font-size: ${rem(24)};
    }
  }

  & > .body > .items > .item > .imagewrapper {
    position: relative;
    width: 100%;
    margin-top: 16px;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 24px;
    }
  }

  & > .body > .items > .item > .imagewrapper > .image {
    display: block;
    width: 100%;
    max-width: 360px;
    height: auto;
    margin-right: auto;
    margin-left: auto;
  }

  & > .body > .items > .item > .text {
    margin-top: 16px;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 24px;
    }
  }
`

const SupportTopComponent = () => {
  return (
    <div className={supportTopStyle}>
      <div className="body">
        <h2 className="title">充実したサポート体制</h2>
        <ul className="items">
          <li className="item">
            <h3 className="title">キックオフミーティング</h3>
            <div className="imagewrapper">
              <StaticImage
                className="image"
                src="../../images/support-kickoff.png"
                objectFit="contain"
                objectPosition="center"
                placeholder="none"
                alt=""
              />
            </div>
            <p className="text">
              採用課題に合った運用フローや質問設計、操作方法などをすり合わせ、円滑に開始できるようサポートします。
            </p>
          </li>
          <li className="item">
            <h3 className="title">定期ミーティング</h3>
            <div className="imagewrapper">
              <StaticImage
                className="image"
                src="../../images/support-meeting.png"
                objectFit="contain"
                objectPosition="center"
                placeholder="none"
                alt=""
              />
            </div>
            <p className="text">
              定期的に振り返りミーティングを実施。レポートの結果から貴社の特徴を分析し、より良い運用に向けた改善点を提案します。
            </p>
          </li>
          <li className="item">
            <h3 className="title">チャットサポート</h3>
            <div className="imagewrapper">
              <StaticImage
                className="image"
                src="../../images/support-chat.png"
                objectFit="contain"
                objectPosition="center"
                placeholder="none"
                alt=""
              />
            </div>
            <p className="text">
              利用でのお困りごとやご不明な点を、チャットからお気軽にお問い合わせ可能です。スピーディーにお悩みを解決します。
            </p>
          </li>
          <li className="item">
            <h3 className="title">サポート資料</h3>
            <div className="imagewrapper">
              <StaticImage
                className="image"
                src="../../images/support-docs.png"
                objectFit="contain"
                objectPosition="center"
                placeholder="none"
                alt=""
              />
            </div>
            <p className="text">
              採用候補者向けの説明用資料や動画を用意。企業・候補者・推薦者向けのヘルプページもあり、運用負担を軽減します。
            </p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SupportTopComponent
