import React from 'react'
import { Link } from 'gatsby'
import { css } from 'linaria'
import IconMalice from '~/images/icon/malice.svg'
import IconSurvey from '~/images/icon/survey.svg'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const functionComplianceTopStyle = css`
  & > .title {
    font-size: ${rem(24)};
    font-weight: 700;
    text-align: center;

    @media screen and (${STYLE.MEDIA.PC}) {
      font-size: ${rem(32)};
    }
  }

  & > .note {
    margin-top: 8px;
    font-size: ${rem(14)};
    font-weight: bold;
    text-align: center;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 12px;
      font-size: ${rem(16)};
    }
  }

  & > .items {
    display: flex;
    flex-direction: column;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-direction: row;
      column-gap: 32px;
    }
  }

  & > .items > .item {
    display: flex;
    position: relative;
    flex-basis: 0;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 40px;
    padding: 32px 24px 24px;
    border-radius: 8px;
    background: var(--color-background-bright);

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 64px;
    }
  }

  & > .items > .item > .button {
    display: block;
    color: var(--color-text);
    text-decoration: none;
  }

  & > .items > .item .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 16px;
    font-size: ${rem(20)};
    font-weight: 700;
    text-align: center;

    &::before {
      content: 'OPTION ' attr(data-count);
      display: flex;
      position: absolute;
      top: -14px;
      right: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      width: 104px;
      height: 28px;
      margin-right: auto;
      margin-left: auto;
      border-radius: 100px;
      background: var(--color-background-key);
      color: var(--color-text-bright);
      font-family: var(--font-family-number);
      font-size: ${rem(13)};
      font-weight: 700;
      white-space: nowrap;

      @media screen and (${STYLE.MEDIA.PC}) {
        top: -15px;
        width: 112px;
        height: 30px;
        font-size: ${rem(15)};
      }
    }
  }

  & > .items > .item .title > .iconwrapper {
    position: relative;
    width: 80px;
    height: 80px;

    @media screen and (${STYLE.MEDIA.PC}) {
      width: 96px;
      height: 96px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      border-radius: 50%;
      background: var(--color-gradient-key);
    }
  }

  & > .items > .item .title .iconwrapper > .icon {
    position: relative;
    z-index: 2;
    width: 100%;
    height: auto;
  }

  & > .items > .item .title > .titletext {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  & > .items > .item .title > .titletext > .subtitle {
    color: var(--color-text-sub);
    font-size: ${rem(20)};
  }

  & > .items > .item .text {
    margin-top: 16px;
  }

  & > .items > .item .orderlist,
  & > .items > .item .list {
    margin-top: 16px;
    list-style: none;
    counter-reset: functionComplianceItem;
  }

  & > .items > .item .orderlist > .item {
    position: relative;
    padding-left: 1.4em;
    counter-increment: functionComplianceItem;

    &::before {
      content: counter(functionComplianceItem) '.';
      position: absolute;
      top: 0;
      left: 0;
      font-family: var(--font-family-number);
    }
  }

  & > .items > .item .list > .item {
    position: relative;
    padding-left: 1em;

    &::before {
      content: '';
      position: absolute;
      top: 0.6em;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: currentColor;
    }
  }

  & > .items > .item .dummybutton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10em;
    margin-top: 16px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 4px;
    padding-bottom: 4px;
    border: 1px solid var(--color-background-key);
    border-radius: 100px;
    background: var(--color-background-bright);
    color: var(--color-text-key);
    font-size: ${rem(11)};
    font-weight: 700;

    @media screen and (${STYLE.MEDIA.PC}) {
      font-size: ${rem(14)};
    }
  }
`

const FunctionComplianceTopComponent = () => {
  return (
    <div className={functionComplianceTopStyle}>
      <h3 className="title">コンプライアンスチェック</h3>
      <p className="note">※追加オプション</p>
      <ul className="items">
        <li className="item">
          <h4 className="title" data-count="01">
            <span className="iconwrapper">
              <IconMalice className="icon" />
            </span>
            <span className="titletext">反社チェック</span>
          </h4>
          <p className="text">
            採用候補者による反社会的勢力との関係性や犯罪関与、不祥事等の情報を公知情報から一覧取得できるサービスです。
          </p>
          <ol className="orderlist">
            <li className="item">検索でヒットした記事を一覧表示</li>
            <li className="item">逮捕・容疑などのキーワードを抽出して表示</li>
            <li className="item">検索結果の懸念度を3段階で評価</li>
          </ol>
        </li>
        <li className="item">
          <Link className="button" to="/backgroundcheck">
            <h4 className="title" data-count="02">
              <span className="iconwrapper">
                <IconSurvey className="icon" />
              </span>
              <span className="titletext">
                バックグラウンドチェック
                <span className="subtitle">(信用調査)</span>
              </span>
            </h4>
            <p className="text">調査機関によって、採用候補者の素行を調査し、追加レポートを取得できるサービスです。</p>
            <ul className="list">
              <li className="item">破産歴・未納歴がないか</li>
              <li className="item">WEB上で不適切な発信がないか（SNSを含む）</li>
              <li className="item">経歴詐称がないか（在籍・実績など）</li>
            </ul>
            <p className="dummybutton">詳細はこちら</p>
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default FunctionComplianceTopComponent
