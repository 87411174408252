import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import { STYLE } from '~/utils/constant'
import { getGatsbyImageData, rem } from '~/utils/helper'
import { Query } from '~/types/graphql-types'

const topicsTopStyle = css`
  & > .body {
    max-width: var(--width-max-content);
    margin-right: auto;
    margin-left: auto;
  }

  & > .body > .title {
    ${STYLE.MIXIN.CONTENT_TITLE};
  }

  & > .body > .items {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    row-gap: 24px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-direction: row;
      column-gap: 24px;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 64px;
      column-gap: 32px;
    }
  }

  & > .body > .items > .item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-basis: calc(100% / 3);
      margin-top: 0;
    }
  }

  & > .body > .items > .item > .button {
    display: grid;
    position: relative;
    grid-column-gap: 24px;
    grid-row-gap: 16px;
    grid-template-columns: 120px 1fr;
    grid-template-rows: repeat(2, auto);
    padding: 24px;
    transition: var(--hover-button-transition);
    border-radius: 8px;
    background: var(--color-background-bright);
    color: var(--color-text);
    text-decoration: none;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding-bottom: 68px;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      padding-bottom: 72px;
    }

    &:hover {
      opacity: var(--hover-button-opacity);
    }
  }

  & > .body > .items > .item > .button > .imagewrapper {
    grid-area: 1 / 1 / 3 / 2;
  }

  & > .body > .items > .item > .button > .imagewrapper > .image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  & > .body > .items > .item > .button > .title {
    grid-area: 1 / 2 / 2 / 3;
    flex-grow: 1;
    font-weight: 700;
  }

  & > .body > .items > .item > .button > .dummybutton {
    display: flex;
    grid-area: 2 / 2 / 3 / 3;
    align-items: center;
    justify-content: center;
    width: 10em;
    padding-top: 4px;
    padding-bottom: 4px;
    border: 1px solid var(--color-background-key);
    border-radius: 100px;
    background: var(--color-background-bright);
    color: var(--color-text-key);
    font-size: ${rem(11)};
    font-weight: 700;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      position: absolute;
      right: 0;
      bottom: 24px;
      left: 0;
      margin-right: auto;
      margin-left: auto;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      font-size: ${rem(14)};
    }
  }
`

const TopicsTopComponent = () => {
  const data: Query = useStaticQuery(
    graphql`
      {
        allFile(filter: { name: { eq: "cms-topics" } }) {
          edges {
            node {
              childrenImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
              url
              name
              id
            }
          }
        }
        allMicrocmsStgTopics(limit: 3) {
          edges {
            node {
              id
              topics_image {
                url
              }
              topics_link
              topics_text
            }
          }
        }
      }
    `,
  )

  return (
    <div className={topicsTopStyle}>
      <div className="body">
        <h2 className="title">トピックス</h2>
        <ul className="items">
          {data &&
            data.allMicrocmsStgTopics.edges.map((edge) => {
              const item = edge.node
              if (!item.topics_link || !item.topics_image || !item.topics_image.url) return

              const gatsbyImageData = getGatsbyImageData(data.allFile, item.topics_image.url)
              const content = (
                <>
                  <div className="imagewrapper">
                    <GatsbyImage className="image" image={gatsbyImageData} alt="" />
                  </div>
                  <p className="title">{item.topics_text}</p>
                  <p className="dummybutton">詳細はこちら</p>
                </>
              )
              return (
                <li className="item" key={item.id}>
                  {item.topics_link.startsWith('http') ? (
                    <a className="button" href={item.topics_link} target="_blank" rel="noreferrer">
                      {content}
                    </a>
                  ) : (
                    <Link className="button" to={item.topics_link}>
                      {content}
                    </Link>
                  )}
                </li>
              )
            })}
        </ul>
      </div>
    </div>
  )
}

export default TopicsTopComponent
